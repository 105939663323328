import {
  getObjectArrayDifference,
  getObjectArrayIntersection,
} from "../helper";
import { GetBooksFromDB, SaveNewBookToDB } from "../offline.book.helpers";
import {
  localBookToRemoteBook,
  getBooksFromIDB,
  getBooksFromRemoteDB,
  removeBooksFromIDB,
  saveBookToLocalDB,
  saveBookToRemoteDB,
  patchBookInLocalDB,
  getCollaboratedBooksFromRemoteDB,
  getBookFromIDB,
  getBookFromRemoteDB,
  getInitialBooksFromRemoteDB,
  syncInitialChaptersWithLocalDB,
} from "./helper";

import { appStore, shelfStore, collaborationStore } from "../../store";
import { toJS } from "mobx";
import { syncBookChapterBodies } from "./chapterBodySync";

export const syncInitialChapters = async (): Promise<void> => {
  const booksInLocalDB = await GetBooksFromDB();
  const booksInRecentOrder = toJS(booksInLocalDB).sort((a, b) => new Date(a.modifiedAt || "").getTime() - new Date(b.modifiedAt || "").getTime()).reverse();
  booksInRecentOrder.forEach (async (book, index) => {
    if(book.collaborated) await syncInitialChaptersWithLocalDB(book._id, true);
    else await syncInitialChaptersWithLocalDB(book._id);
    await syncBookChapterBodies(book._id);

    await collaborationStore.loadCollabBooks();
    if(index === booksInRecentOrder.length - 1) {
      appStore.setInitialChaptersLoaded(true);
    }
  });
};

export const syncBooks = async (): Promise<void> => {
  let booksInLocalDB = await getBooksFromIDB();
  const { books, deletedBookIds } = await getBooksFromRemoteDB();

  //Get collaborated_books in remoteDB
  const  { books:collaborated_books } = await getCollaboratedBooksFromRemoteDB();

  // Add identifying property to each collaborated book
  const collaboratedBooksWithId = collaborated_books.map(book => ({
    ...book,
    collaborated: true
  })); 

  const booksInRemoteDB = [...books, ...collaboratedBooksWithId];

  /**
   * Removed books deleted in remoteDB from localDB
   * LocalDB should not have any newly deleted books since book deletion is not allowed offline
   */
  booksInLocalDB = booksInLocalDB.filter(
    (book) => deletedBookIds.indexOf(book._id) === -1
  );
  await removeBooksFromIDB(deletedBookIds);
  const booksMissingInLocalDB = getObjectArrayDifference(
    booksInRemoteDB,
    booksInLocalDB,
    "_id"
  );
  const booksMissingInRemoteDB = getObjectArrayDifference(
    booksInLocalDB,
    booksInRemoteDB,
    "_id"
  );
  const booksInBothLocalAndRemote = getObjectArrayIntersection(
    booksInRemoteDB,
    booksInLocalDB,
    "_id"
  );
  /** Save books missing in local db to local db */
  for(const book of booksMissingInLocalDB){
    await saveBookToLocalDB(book);
  }
  /** Save books missing in remote db to remote db */
  const booksToSaveInRemoteDB = booksMissingInRemoteDB.map((book) =>
    localBookToRemoteBook(book)
  );
  for (const book of booksToSaveInRemoteDB) {
    const timeStamp = await saveBookToRemoteDB(book);
    await patchBookInLocalDB({
      _id: book._id,
      lastSuccessfulSync: timeStamp,
      allChangesSynced: true,
    });
  }
  /** Handle books in both local db and remote db */
  /** TODO: Do a promise.all here */
  booksInBothLocalAndRemote.map(async (book) => {
    const localBook = booksInLocalDB.find((local) => local._id === book._id);
    const remoteBook = booksInRemoteDB.find(
      (remote) => remote._id === book._id
    );
    if (localBook && remoteBook)
      await syncRemoteAndLocalBookChanges(remoteBook, localBook);
  });
};


/** sync specific book */
export const syncBook = async (bookId: string): Promise<void> => {
  const bookInLocalDB = await getBookFromIDB(bookId);
  const bookInRemoteDB = await getBookFromRemoteDB(bookId);
  
  /** If the book exists in the remote DB but not in the local DB, save it to the local DB */
  if (bookInRemoteDB && !bookInLocalDB) {
    await saveBookToLocalDB(bookInRemoteDB);
    return;
  }

  /** If the book exists in the local DB but not in the remote DB, save it to the remote DB */
  if (bookInLocalDB && !bookInRemoteDB) {
    const bookToSaveInRemoteDB = localBookToRemoteBook(bookInLocalDB);
    const timeStamp = await saveBookToRemoteDB(bookToSaveInRemoteDB);
    await patchBookInLocalDB({
      _id: bookInLocalDB._id,
      lastSuccessfulSync: timeStamp,
      allChangesSynced: true,
    });
    return;
  }
  
  /** If the book exists in both local and remote DB, sync changes */ 
  if (bookInLocalDB && bookInRemoteDB) {
    await syncRemoteAndLocalBookChanges(bookInRemoteDB, bookInLocalDB);
  }
};

export const syncRemoteAndLocalBookChanges = async (
  remoteBook: IBookStore.RemoteBook,
  localBook: IBookStore.ExpandedBook
): Promise<void> => {
  /**
   * there can not be any remotebooks with !lastUpdatedAt
   * there can not be any localbooks available both locally and remotely with !lastSuccessfulSync
   */
  if (!remoteBook.lastUpdateAt || !localBook.lastSuccessfulSync) {
    return;
  }
  /** remotebook has no new updates */
  if (remoteBook.lastUpdateAt === localBook.lastSuccessfulSync) {
    /** localbook has no new updates */
    if (localBook.allChangesSynced) {
      return;
      /** localbook has unsynced new updates, save localbook to remote db */
    } else {
      const bookToSaveInRemoteDB = localBookToRemoteBook(localBook);
      const timestamp = await saveBookToRemoteDB(bookToSaveInRemoteDB);
      await patchBookInLocalDB({
        _id: bookToSaveInRemoteDB._id,
        lastSuccessfulSync: timestamp,
        allChangesSynced: true,
      });
    }
    /** remotebook has new updates since the locabook's last successful sync */
  } else {
    /** localbook has no new updates, save remotebook to localdb */
    if (localBook.allChangesSynced) {
      await saveBookToLocalDB(remoteBook);
      /** localbook has new updates, should conflict resolve */
    } else {
      /** get conflict resolved book */
      const commonBook = doChapterLevelBookSync(remoteBook, localBook);
      /** update remote db with the conflict resolved book */
      const timeStamp = await saveBookToRemoteDB(commonBook);
      /** update localbook with the conflict resolved book */
      await saveBookToLocalDB({...commonBook, lastUpdateAt: timeStamp});
    }
  }
};

/**
 * @param remoteBook Book from the server
 * @param localBook Book from local db
 * @returns A book that has changes from both server and local db
 *
 * If a chapter exists in both remote and local book, the chapter meta from the remote book gets
 * prioritized. However, the chapter bodies should merge accordingly using yjs elsewhere
 * in the sync logic.
 */
export const doChapterLevelBookSync = (
  remoteBook: IBookStore.RemoteBook,
  localBook: IBookStore.ExpandedBook
): IBookStore.RemoteBook => {
  /**
   * Remove deleted chapters in the remote book, from the localbook.
   * Chapters can not be deleted while offline, therefore the localbook should not have
   * newly deleted chapters.
   */
  if (remoteBook.deletedChapterIds?.length) {
    const isDeletedChapter = (chapter: IChapterStore.ChapterMeta) =>
      remoteBook.deletedChapterIds?.includes(chapter._id);
    localBook.chapters =
      localBook.chapters?.filter(isDeletedChapter);
    localBook.frontMatter =
      localBook.frontMatter?.filter(isDeletedChapter);
  }
  const syncedBook = remoteBook;
  /** 
   * Append chapters / chapterids missing in remote book (chapters created offline) to 
   * the resolving book
   */
  const chaptersMissingInRemoteBook = getObjectArrayDifference(
    localBook.chapters,
    remoteBook.chapters,
    "_id"
  );
  const chapterIdsMissingInRemoteBook = chaptersMissingInRemoteBook.map(
    (chapter) => chapter._id
  );
  const frontMatterMissingInRemoteBook = getObjectArrayDifference(
    localBook.frontMatter,
    remoteBook.chapters,
    "_id"
  );
  const frontMatterIdsMissingInRemoteBook = frontMatterMissingInRemoteBook.map(
    (chapter) => chapter._id
  );

  syncedBook.chapters = [
    ...syncedBook.chapters,
    ...[...chaptersMissingInRemoteBook, ...frontMatterMissingInRemoteBook],
  ];
  syncedBook.chapterIds = [
    ...syncedBook.chapterIds,
    ...chapterIdsMissingInRemoteBook,
  ];
  syncedBook.frontMatterIds = [
    ...syncedBook.frontMatterIds,
    ...frontMatterIdsMissingInRemoteBook,
  ];
  return syncedBook;
};


export const syncBookBaseData = async(bookId: string): Promise<IBookStore.ExpandedBook> => {
  await syncBook(bookId);
  await syncBookChapterBodies(bookId);
  return await getBookFromIDB(bookId);
};