import React, { ReactNode, useEffect, useState } from "react";
import { Col, Menu, Row } from "antd";
import { observer } from "mobx-react";

import useRootStore from "../../store/useRootStore";
import {
  AddUsersIcon,
  ArrowDownIcon,
  ArrowUpIcon,
  CollaborationMenuIcon,
  TickSquare,
  UserIcon,
} from "../../content/icons";
import { AtticusSelect } from "../Shared/Form";
import { Button, ButtonType } from "../Shared/Buttons";
import { AtticusDropdown } from "../Shared/Dropdowns";
import { TComment } from "../Plate/plugins/comments/types";
import Can from "../casl/Can";
import { TTrackChange, TrackChangeReply } from "../Plate/plugins/track-changes/types";
import { TrackChanges } from "../Plate/plugins/track-changes/components/TrackChanges";
import { Comments } from "../Plate/plugins/comments/components";
import { ScrollContainer } from "../Shared/Layouts";
import { Value } from "@udecode/plate";
import { CommentsAndTC } from "./CommentsAndTC";
import { CommentOrTC } from "../../types/comments";
import { can } from "../casl/helpers";

interface CollaborationRHSMenuProps {
  resolveComment?: (commentId: string) => void;
  submitReplyComment?: (replyValue: string) => void;
  deleteComment?: (comment: TComment) => void;
  updateComment?: (comment: TComment) => void;
  approveTrackChange?: (trackChange: TTrackChange[]) => void;
  declineTrackChange?: (trackChange: TTrackChange[]) => void;
  submitReplyTrackChange?: (replyValue: Value, trackChange: TTrackChange) => void;
  updateTrackChangeReply?:(trackChangeNode: TTrackChange, updatedReply: string, reply: TrackChangeReply)=> void;
  deleteReply?:(trackChange: TTrackChange, reply:TrackChangeReply)=> void;
}

export const CollaborationRHSMenu: React.FC<CollaborationRHSMenuProps> =
  observer(
    ({
      resolveComment,
      submitReplyComment,
      deleteComment,
      updateComment,
      approveTrackChange,
      declineTrackChange,
      submitReplyTrackChange,
      updateTrackChangeReply,
      deleteReply,
    }) => {
      const optionList = [
        {
          value: "All",
          label: "All",
        },
        {
          value: "Comments",
          label: "Comments",
        },

        ...(can("view", "track-change-content")
          ? [
            {
              value: "Edits",
              label: "Edits",
            },
          ]
          : []),
      ];

      const [isResolvedComments, setIsResolvedComments] =
        useState<boolean>(false);
      const [selectedOption, setSelectedOption] = useState<string>("");
      const [activeOption, setActiveOption] = useState<string>("Comments");
      const [commentCount, setCommentCount] = useState<number>(0);
      const [trackChangeCount, setTrackChangeCount] = useState<number>(0);
      const [parentComments, setParentComments] = useState<TComment[]>([]);

      const {
        activeCommentId,
        comments,
        commentsAndTrackChanges,
        setActiveCommentId,
      } = useRootStore().commentsStore;
      const { trackChanges, activeTrackChangeId, setActiveTrackChangeId } = useRootStore().trackChangesStore;

      useEffect(() => {
        const count = comments.filter(
          (comment) =>
            !comment.parentId &&
            (!comment.isResolved || comment.isResolved === undefined)
        ).length;
        setCommentCount(count);
        setTrackChangeCount(trackChanges.length);
        setParentComments(getAllParentComments());
      }, [comments, trackChanges]);

      useEffect(() => {
        if (
          selectedOption === "unresolved-comments" ||
          (selectedOption === "resolved-comments" &&
            activeOption !== "Comments")
        ) {
          setActiveOption("Comments");
        }
      }, [selectedOption]);

      const getAllParentComments = (): TComment[] =>{
        return comments.filter(
          (comment) =>
            !comment.parentId && !comment.isResolved
        );
      };

      const handleNext = () => {
        activeOption === "Comments"
          ? handleNextComment()
          : activeOption === "Edits"
          ? handleNextTrackChange()
          : handleNextAll();
      };

      const handlePrevious = () => {
        activeOption === "Comments"
          ? handlePreviousComment()
          : activeOption === "Edits"
          ? handlePreviousTrackChange()
          : handlePreviousAll();
      };

      const handleNextComment = () => {
        const index = findCommentIndexById(parentComments, activeCommentId);
        setActiveCommentId(parentComments[index+1].id);
      };

      const handlePreviousComment = () => {
        const index = findCommentIndexById(parentComments, activeCommentId);
        setActiveCommentId(parentComments[index-1].id);
      };

      const findCommentIndexById = (comments: TComment[], id: string): number => {
        return comments.findIndex(comment => comment.id === id);
      };

      const handleNextTrackChange = () => {
        const index = findTrackChangeIndexById(trackChanges, activeTrackChangeId);
        setActiveTrackChangeId(trackChanges[index+1].tc.trackChanges.tcId);
      };

      const handlePreviousTrackChange = () => {
        const index = findTrackChangeIndexById(trackChanges, activeTrackChangeId);
        setActiveTrackChangeId(trackChanges[index-1].tc.trackChanges.tcId);
      };

      const findTrackChangeIndexById = (trackChanges: TTrackChange[][], id: string): number => {
        for (let i = 0; i < trackChanges.length; i++) {
            if (trackChanges[i].some(trackChange => trackChange.tc.trackChanges.tcId === id)) {
                return i;
            }
        }
        return -1; 
    };

      const handleFunctionChange = (value: string) => {
        setActiveOption(value);
      };

      const getSelectedIcon = (menuOption: string): ReactNode => {
        if (selectedOption === menuOption) return <TickSquare color="#00be95" />;
        else return null;
      };

      const handleNextAll = () => {
        const activeItemId = activeCommentId || activeTrackChangeId;
        const index = findItemIndexById(commentsAndTrackChanges, activeItemId);
        const nextItem: CommentOrTC = commentsAndTrackChanges[index + 1];
        if (nextItem.type === "comment") {
          if (activeTrackChangeId) {
            setActiveTrackChangeId(null);
          }
          setActiveCommentId(nextItem.id);
        } else {
          if (activeCommentId) {
            setActiveCommentId(null);
          }
          setActiveTrackChangeId(nextItem.id);
        }
      };

      const handlePreviousAll = () => {
        const activeItemId = activeCommentId || activeTrackChangeId;
        const index = findItemIndexById(commentsAndTrackChanges, activeItemId);
        const prevItem: CommentOrTC = commentsAndTrackChanges[index - 1];
        if (prevItem.type === "comment") {
          if (activeTrackChangeId) {
            setActiveTrackChangeId(null);
          }
          setActiveCommentId(prevItem.id);
        } else {
          if (activeCommentId) {
            setActiveCommentId(null);
          }
          setActiveTrackChangeId(prevItem.id);
        }
      };

      const findItemIndexById = (
        commentsAndTrackChanges: CommentOrTC[],
        activeItemId: string
      ): number => {
        return commentsAndTrackChanges.findIndex(
          (item) => item.id === activeItemId
        );
      };

      const isDownArrowDisabled = (): boolean => {
        if (activeOption === "Comments")
          return (
            findCommentIndexById(parentComments, activeCommentId) + 1 >=
            parentComments.length
          );
        else if (activeOption === "Edits")
          return (
            findTrackChangeIndexById(trackChanges, activeTrackChangeId) + 1 >=
            trackChanges.length
          );
        else {
          const itemId = activeCommentId || activeTrackChangeId;
          return (
            findItemIndexById(commentsAndTrackChanges, itemId) + 1 >=
            commentsAndTrackChanges.length
          );
        }
      };

      const isUpArrowDisabled = (): boolean => {
        if (activeOption === "Comments")
          return findCommentIndexById(parentComments, activeCommentId) - 1 < 0;
        else if (activeOption === "Edits")
          return (
            findTrackChangeIndexById(trackChanges, activeTrackChangeId) - 1 < 0
          );
        else {
          const itemId = activeCommentId || activeTrackChangeId;
          return findItemIndexById(commentsAndTrackChanges, itemId) - 1 < 0;
        }
      };
      
      return (
        <div className="collaboration-rhs-wrapper">
          <div className="collaboration-settings-wrapper">
            <Row className="settings-header">
              <Col className="comment-label-col" span={7.5}>Comments</Col>
              <Col className="count-wrapper" span={2}>{commentCount}</Col>
              <Col span={1}></Col>
              <Can action={"view"} subject={"edits-count"}>
                <Col className="edits-label-col" span={4}>Edits</Col>
                <Col className="count-wrapper" span={2}>{trackChangeCount}</Col>
              </Can>
              <Col span={3} className="user-icon-wrapper">
                <UserIcon />
              </Col>
              <Can action={"view"} subject={"invite-collaborators"}>
                <Col span={1}>
                  <AddUsersIcon />
                </Col>
              </Can>
            </Row>
            <Row className="settings-options-row">
              <Col span={19}>
                <AtticusSelect
                  type="secondary"
                  defaultValue={"Comments"}
                  value={activeOption}
                  selectOptions={optionList}
                  className="collaboration-select"
                  onChange={handleFunctionChange}
                />
              </Col>
              <Col span={3}>
                <AtticusDropdown
                  placement="bottomRight"
                  trigger={["click"]}
                  overlay={
                    <Menu
                      onClick={(e) => {
                        setSelectedOption(e.key);
                      }}
                      selectedKeys={[selectedOption]}
                      className="collaboration-dropdown-menu"
                    >
                      <Menu.Item key={"view-editor"} icon={getSelectedIcon("view-editor")}>View Editor</Menu.Item>
                      <Menu.Item key={"view-beta-reader"} icon={getSelectedIcon("view-beta-reader")}>
                        View beta reader
                      </Menu.Item>
                      <Menu.Item key={"view-co-writer"} icon={getSelectedIcon("view-co-writer")}>
                        View co-writer
                      </Menu.Item>
                      <Menu.Item key={"view-co-publisher"} icon={getSelectedIcon("view-co-publisher")}>
                        View co-publisher
                      </Menu.Item>
                      <Menu.Divider />
                      <Menu.Item
                        key={"unresolved-comments"}
                        icon={getSelectedIcon("unresolved-comments")}
                        onClick={() => setIsResolvedComments(false)}
                      >
                        Unresolved comments
                      </Menu.Item>
                      <Menu.Divider />
                      <Menu.Item
                        key={"resolved-comments"}
                        icon={getSelectedIcon("resolved-comments")}
                        onClick={() => setIsResolvedComments(true)}
                      >
                        Resolved comments
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <div>
                    <CollaborationMenuIcon />
                  </div>
                </AtticusDropdown>
              </Col>
              <Col span={2}>
                <Row>
                  <Button
                    className="collaboration-pagination-btn"
                    type={ButtonType.GHOST}
                    icon={
                      <ArrowUpIcon
                        color={isUpArrowDisabled() ? "#d1d2d3" : "#00BE95"}
                      />
                    }
                    disabled={isUpArrowDisabled()}
                    onClick={handlePrevious}
                  />
                </Row>
                <Row>
                  <Button
                    className="collaboration-pagination-btn"
                    type={ButtonType.GHOST}
                    icon={
                      <ArrowDownIcon
                        color={isDownArrowDisabled() ? "#d1d2d3" : "#00BE95"}
                      />
                    }
                    onClick={handleNext}
                    disabled={isDownArrowDisabled()}
                  />
                </Row>
              </Col>
            </Row>
          </div>

          <div>
            <ScrollContainer>
              {activeOption === "Comments" ? (
                <Comments
                  resolveComment={resolveComment}
                  submitReplyComment={submitReplyComment}
                  deleteComment={deleteComment}
                  updateComment={updateComment}
                  isResolvedComments={isResolvedComments}
                />
              ) : activeOption === "Edits" ? (
                <TrackChanges
                  approveTrackChange={approveTrackChange}
                  declineTrackChange={declineTrackChange}
                  submitReplyTrackChange={submitReplyTrackChange}
                  updateTrackChangeReply={updateTrackChangeReply}
                  deleteReply={deleteReply}
                />
              ) : (
                <CommentsAndTC
                  resolveComment={resolveComment}
                  submitReplyComment={submitReplyComment}
                  deleteComment={deleteComment}
                  updateComment={updateComment}
                  approveTrackChange={approveTrackChange}
                  declineTrackChange={declineTrackChange}
                  submitReplyTrackChange={submitReplyTrackChange}
                  updateTrackChangeReply={updateTrackChangeReply}
                  deleteReply={deleteReply}
                />
              )}
            </ScrollContainer>
          </div>
        </div>
      );
    }
  );
